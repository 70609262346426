import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"

// used to make child routes show as active
const partlyActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
})

const PartlyActiveLink = ({ className, ...rest }) => (
  <Link getProps={partlyActive(className)} {...rest} />
)
//end

const NavItem = styled(PartlyActiveLink)`
  text-decoration: none;
  color: ${palette.primaryContrastLight};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1em;
  transition: all 200ms ease-in;
  position: relative;
  z-index: 6;
  //font-size: 1.5rem;
  padding: 0.1em 0.25em;
  text-align: center;

  :hover {
    color: ${palette.primaryContrastStong};

    ::after {
      width: 100%;
    }
  }

  &.active {
    font-weight: bolder;
    color: ${palette.primaryContrastStong};
  }

  br {
    display: none;
  }

  ${respondTo.lg`
    margin: 20px 1em;

    br{
      display:block;
    }
  `}

  &.pfp {
    border-style: solid;
    border-radius: 2em;
    border-width: medium;
    padding: 0 1em;
    //margin-top:10vw;

    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};
      text-decoration: none;
    }

    ${respondTo.lg`
      margin:0 5em;  
    `}
  }

  &.self-serve {
    background-color: ${palette.primaryContrast};
    color: ${palette.primary};
    border-radius: 2em;
    padding: 0 1em;
    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};
      text-decoration: none;
    }
    ${respondTo.md`
  background-color: ${palette.secondary};
  color: ${palette.primaryContrast};
  `}
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/about-you" activeClassName="active">
        About You
      </NavItem>
      <NavItem to="/what-we-do" activeClassName="active">
        What We Do
      </NavItem>
      <NavItem to="/true-wealth-group" activeClassName="active">
        True Wealth Group
      </NavItem>
      <NavItem to="/finding-true-wealth" activeClassName="active">
        Blog
      </NavItem>
      <NavItem to="/contact-us" activeClassName="active">
        Contact Us
      </NavItem>
     
      <NavItem as="a" href="https://watsonlaird.gb.pfp.net/" className="pfp">
        Client Portal
      </NavItem>
    </>
  )
}

export default NavbarLinks
