export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "1350px",
  xl: "1500px",
}

export const palette = {
  primary: "#853a48",
  primaryContrast: "#eeeeee",
  primaryContrastStong: "#fff",
  primaryContrastLight: "#c7c7c7",

  secondary: "#d93c65",
  secondaryLight: "#e98da4",
}
