import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import logo from "../../images/watson-laird-logo-white.svg"
import { respondTo } from "../../_respondTo"

const LogoWrap = styled.div`
  margin: 0;
  width: 13.35vh; //(1000 / 524) * 7vh - ratio of logo * desired height
  min-width: 124px;
  display: flex;
  align-items: center;
`

const Logo = () => {
  return (
    <LogoWrap as={Link} to="/">
      <img
        src={logo}
        alt="Watson Laird logo"
        style={{ margin: "0.5vh 2em 0.5vh 0" }}
      ></img>
    </LogoWrap>
  )
}

export default Logo
