/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Footer from "./footer"
import "./layout.css"
//import LiveChat from "./live-chat/livechat"

import Navbar from "./navbar/navbar"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <script
          defer
          src="https://unpkg.com/@tinybirdco/flock.js"
          data-host="https://api.tinybird.co"
          data-token="p.eyJ1IjogIjlmN2NiNTlhLTljYWItNGYwYS1iYzZlLTlhOTNhNTM3M2YyOCIsICJpZCI6ICI1NmI0YmVjNi1iZGEwLTQwNzQtODliZi01ZGUzZjYyMTk4NjUifQ.Ef-7W3ZC8238x7fnJyDlghUhNWA2JWRBB5mmvGODoj0"
        ></script>
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

export default Layout
