import { Link } from "gatsby"

import React from "react"
import styled from "styled-components"
import { palette } from "../_variables"

import CookieConsent from "react-cookie-consent"

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${palette.primary};
  text-align: center;
  padding: 0.75em 0.75em 2em 0.75em;
`

const FooterText = styled.p`
  color: white;
  margin: 0 auto 0.5em auto;
  font-size: small;
  line-height: 1.3;
`

const FooterLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 960px;
  margin: 0 auto;
  color: white;
`

const FooterLink = styled(Link)`
  color: white;
  font-size: small;
`

function Header() {
  return (
    <FooterWrapper>
      <FooterLinkWrapper style={{ marginBottom: "1.5em" }}>
        <FooterLink to={"/important-information"}>
          Important Information
        </FooterLink>
        <FooterLink to={"/privacy-policy"}>Privacy Policy</FooterLink>
        <FooterLink to={"/privacy-policy#cookies"}>Cookie Policy</FooterLink>

        <FooterLink to={"/complaints-policy"}>Complaints Policy</FooterLink>

        <FooterLink to={"/important-information#fos"}>
          Financial Ombudsman Service
        </FooterLink>
      </FooterLinkWrapper>

      <FooterText>
        Copyright © {new Date().getFullYear()}, Watson Laird Ltd
      </FooterText>
      <FooterText>
        Watson Laird Limited is registered in Northern Ireland with company
        number NI611950. Registered office is located at 5 Cloughmore Terrace,
        Warrenpoint, Newry, Northern Ireland, BT34 3HP. Watson Laird Limited is
        an appointed representative of Navigator Financial Planning Limited
        which is authorised and regulated by the Financial Conduct Authority.
        Our FCA number is 538617.
      </FooterText>

      <FooterText>
        Both Watson Laird and Navigator Financial Planning are part of the{" "}
        <FooterLink to="/true-wealth-group">True Wealth Group</FooterLink>.
      </FooterText>

      <CookieConsent
        style={{
          fontSize: "0.75em",
          padding: "0.05em",
          backgroundColor: "rgba(115,115,115,0.85)",
        }}
        buttonStyle={{ margin: "0 auto", padding: "2 5", order: "0" }}
        contentStyle={{ margin: "0" }}
        expires={365}
      >
        This website uses cookies to monitor its use.
      </CookieConsent>
    </FooterWrapper>
  )
}

export default Header
